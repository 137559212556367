.foodList>div:nth-child(odd) {
    background: #f7f7f7
}

.foodList>div:nth-child(even) {
    background: white;
}

.foodList>div:hover {
    background: #ececec;
}

.foodList>div {
    padding-left:10px;
    padding-right:5px;
}

.foodList {
    border-radius: 15px;
    background-color: white;
    overflow: hidden;
    flex-basis: 100%;
}