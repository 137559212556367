.background {
    align-items: center;
    align-content: center;
    justify-content: center;
    min-height: 100vh;
}

@media only screen and (min-width: 960px) {
    body {
        background-size: 100vw;
        background-attachment: fixed;
        background-image: url(../../assets/images/background-unauth.jpg);
    }
}

.contentBox {
    max-width: 1000px !important;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px #000;
    background-color: white;
    margin-top: 3rem !important;
}

.sidebarImage {
    background-position: center;
    background-size: cover;
    border-radius: 0 8px 8px 0;
}

.contentPadding {
    padding: 3rem;
    padding-bottom: 1rem;
}

.copyright {
    text-align: center;
    font-size: 10px;
    color: white;
}

.copyright a, .copyright a:hover {
    text-decoration: underline;
    color: white;
    margin: 0 5px;
}

@media only screen and (max-width: 599px) {
    .contentPadding {
        padding: 1.5rem;
    }
    .contentBox {
        margin-top: 0.5rem !important;
    }
}

.languageActive {
    color: #444444 !important;
    background-color: white !important;
    cursor: pointer !important;
}

.languageActive:hover {
    text-decoration: underline !important;
}

.languageInactive {
    color: white !important;
    background-color: transparent !important;
    cursor: pointer !important;
}

.languageInactive:hover {
    color: #444444 !important;
    background-color: rgba(255, 255, 255, 0.8) !important;
    text-decoration: underline !important;
}