html {
  overflow-y: scroll;
}

.shadowBox {
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.shadowBoxHover:hover {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.8), 0px 1px 1px 0px rgba(0, 0, 0, 0.56), 0px 1px 3px 0px rgba(0, 0, 0, 0.48);
  cursor: pointer;
}

.tabsContainer {
  display: flex;
}

.tabsContainer .tab {
  border: 1px solid #cccccc;
  background-color: white;
  padding: 5px 10px;
  flex: 1;
  text-align: center;
  color: #cccccc;
  cursor: pointer;
  text-wrap: nowrap;
}

.tabsContainer .tab:hover {
  text-decoration: underline;
}

.tabsContainer .tabActive {
  border: 1px solid #17B169;
  background-color: #17B169;
  color: white;
  font-weight: 600;
}

.tabsContainer .tabActiveGrey {
  border: 1px solid #aaaaaa;
  background-color: #aaaaaa;
  color: white;
  font-weight: 600;
}

.tabsContainer .tabActiveRed {
  border: 1px solid #c96464;
  background-color: #c96464;
  color: white;
  font-weight: 600;
}

.tabsContainer .tab:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.tabsContainer .tab:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.hoverUnderline:hover {
  text-decoration: underline;
}

.hoverBold:hover {
  font-weight: bold;
}

.hoverGrey:hover {
  cursor: pointer;
  background-color: #eeeeee;
}

.showOnHover .hiddenChild {
  visibility: hidden;
}

.showOnHover:hover .hiddenChild {
  visibility: visible;
}

.blackButton {
  display: flex;
  align-items: center;
  border: 2px solid #ffffff;
  border-radius: 10px;
  padding: 10px 50px;
  color: #000000;
  font-weight: 700;
  cursor: pointer;
  white-space: nowrap;
  background-color: #ffffff;
  box-sizing: border-box;
}

.greenButton {
  display: flex;
  align-items: center;
  border: 2px solid #17B169;
  border-radius: 10px;
  padding: 10px 50px;
  color: #17B169;
  font-weight: 700;
  cursor: pointer;
  white-space: nowrap;
  background-color: #ffffff;
  justify-content: center;
  box-sizing: border-box;
}

.greenButtonSmall {
  display: flex;
  align-items: center;
  border: 1px solid #17B169;
  border-radius: 10px;
  padding: 4px 10px;
  color: #17B169;
  font-weight: 700;
  cursor: pointer;
  white-space: nowrap;
  background-color: #ffffff;
  box-sizing: border-box;
}

.greenButton.active,
.greenButton:hover,
.greenButtonSmall.active,
.greenButtonSmall:hover,
.blackButton:hover {
  background-color: #17B169;
  color: white;
}

.redButtonSmall {
  display: flex;
  align-items: center;
  border: 1px solid firebrick;
  border-radius: 10px;
  padding: 4px 10px;
  color: firebrick;
  font-weight: 700;
  cursor: pointer;
  white-space: nowrap;
  background-color: #ffffff;
  box-sizing: border-box;
}

.redButton.active,
.redButton:hover,
.redButtonSmall.active,
.redButtonSmall:hover,
.redButtonSmall:hover {
  background-color: firebrick;
  color: white;
}

.hoverGreen:hover {
  color: #17B169;
}

.hoverBlue:hover {
  color: #1034a6;
}

.hoverRed:hover {
  color: firebrick;
}

a.plainLink,
a.plainLink:hover,
a.plainLink:visited,
a.plainLink:active {
  color: inherit;
  text-decoration: none;
}

.verticalTabs {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.verticalTabs>div {
  padding: 10px;
  font-weight: 400;
  display: flex;
  gap: 10px;
  align-items: center;
}

.verticalTabs>div:hover {
  font-weight: 600;
  cursor: pointer;
}

.verticalTabs>div.active {
  background-color: #17B169;
  color: #ffffff;
  border-radius: 5px;
  font-weight: 600;
}

.greenTable {
  width: 100%;
  border-spacing: 0px;
}

.greenTable thead {
  background-color: #17B169;
  color: #ffffff;
  font-weight: 600;
}

.greenTable tbody td {
  border-bottom: 1px solid #cccccc;
}

.greenTable tbody tr:nth-child(odd) {
  background-color: #eeeeee;
}

.greenTable tbody tr:nth-child(even) {
  background-color: #dddddd;
}

.greenTable tbody tr:hover {
  background-color: #cccccc;
}

.greenTable td {
  padding: 10px;
}

.scrollTable {
  width: 100%;
  overflow-x: auto;
}

.scrollTable td {
  white-space: nowrap;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}