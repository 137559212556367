.menuItem {
    border-radius: 25px !important;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    color: white !important;
    max-width: calc(100% - 20px);
}

.menuItem:hover {
    background-color: #ced359 !important;
    color: #111820 !important;
}

.menuItem.active {
    border-radius: 25px 0 0 25px !important;
    background-color: white !important;
    color: #111820 !important;
    max-width: 100%;
}

.menuItem.active:before {
    content: "";
    position: absolute;
    background-color: transparent;
    top: -50px;
    right: 0px;
    height: 50px;
    width: 25px;
    border-bottom-right-radius: 25px;
    box-shadow: 0 25px 0 0 white;
}

.menuItem.active:after {
    content: "";
    position: absolute;
    background-color: transparent;
    bottom: -50px;
    right: 0px;
    height: 50px;
    width: 25px;
    border-top-right-radius: 25px;
    box-shadow: 0 -25px 0 0 white;
}


.menuItemMobile {
    border-radius: 25px !important;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    color: white !important;
    max-width: calc(100% - 20px);
}

.menuItemMobile:hover {
    background-color: #ced359 !important;
    color: #111820 !important;
}

.menuItemMobile.active {
    border-radius: 25px !important;
    background-color: white !important;
    color: #111820 !important;
    max-width: 100%;
}