.track-vertical {
    top: 2px;
    bottom: 2px;
    left: 2px;
    border-radius: 3px;
    background: rgba(255, 255, 255, 0.111);
}

.track-vertical.thumb-vertical {
    position: relative;
    display: block;
    width: 100%;
    cursor: pointer;
    border-radius: inherit;
    background-color: rgba(123, 154, 255, 0.418); 
}