.row {
    border: 1px solid transparent;
}

.row:hover, .rowHover {
    border: 1px solid black;
    border-radius: 8px;
    background-color: #ececec;
    cursor: pointer;
}

.circle {
    font-size: 60px;
    flex-shrink: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #ececec;
}

.mobileCircle {
    font-size: 35px;
    flex-shrink: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #ececec;
}

.row:hover .circle{
    background-color: white;
}