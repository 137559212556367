.portionsList>div:nth-child(odd) {
    color: #3c4858;
}

.portionsList>table {
    margin: 0 0 0 auto;
}

@media screen and (min-width: 961px) {
    .portionsList>table {
        margin: 0 auto;
    }
}

.portionsList>div:nth-child(even) {
    color: #6e3076;
}

.portionsList>tr:nth-child(odd) {
    color: #3c4858;
}

.portionsList>tr:nth-child(even) {
    color: #6e3076;
}

.blueText, .hoverBlueText:hover {
    color: blue !important;
}

.greenText, .hoverGreenText:hover {
    color: green !important;
}

.redText, .hoverRedText:hover {
    color: red !important;
}

.rowAlternatingGrey>div:nth-child(odd) {
    background-color: #f7f7f7;
}

.rowAlternatingGrey>div:nth-child(even) {
    background-color: #dddddd;
}

.rowAlternating>div:nth-child(odd) {
    background-color: white;
}

.rowAlternating>div:nth-child(even) {
    background-color: #f7f7f7;
}

.rowAlternating>div:hover, .rowHover:hover {
    background-color: #ececec;
}

.rowAlternating>div .circleButton, .rowHover:hover .circleButton {
    background-color: #ececec;
}

.rowAlternating>div:hover .circleButton, .rowHover:hover .circleButton {
    background-color: white;
}

.rowAlternatingPlain>div:nth-child(odd) {
    background-color: white;
}

.rowAlternatingPlain>div:nth-child(even) {
    background-color: #f7f7f7;
}

.cellHeader {
    font-size: 20px;
    font-weight: 400;
    color: white;
    background-color: #6e3076;
    padding: 5px;
    border-radius: 8px 8px 0 0;
    text-align: center;
    border: 1px solid #6e3076;
}

.cellFooter {
    font-size: 20px;
    font-weight: 400;
    color: white;
    background-color: #6e3076;
    padding: 5px;
    border-radius: 0 0 8px 8px;
    text-align: center;
    border: 1px solid #6e3076;
}

.cellBorders {
    padding: 5px;
    text-align: center;
    border-left: 1px solid #6e3076;
    border-right: 1px solid #6e3076;
}

.purple {
    color: #6e3076;
}

.title {
    font-size: 32px;
    font-weight: bold;
}

.groupBlue {
    border-left: 5px solid #17a2b8;
}

.categoryBox {
    box-shadow: 0px 0px 2px 0px black;
    border-radius: 8px;
    background-color: #FFFFFF;
    cursor: pointer;
    text-align: center;
    font-size: 18px;
    color: white;
}

.categoryBox:hover {
    box-shadow: 0px 0px 5px 0px black;
    text-decoration: underline;
    text-decoration-color: #dce188;
    color: #dce188;
}

.MuiButtonGroup-root svg {
    font-size: 20px;
    padding-right: 10px;
}

.sortableList div {
    z-index: 20;
}

.languageActive {
    color: #444444 !important;
    background-color: white !important;
    cursor: pointer !important;
}

.languageActive:hover {
    text-decoration: underline !important;
}

.languageInactive {
    color: white !important;
    background-color: transparent !important;
    cursor: pointer !important;
}

.languageInactive:hover {
    color: #444444 !important;
    background-color: rgba(255, 255, 255, 0.8) !important;
    text-decoration: underline !important;
}

.hover:hover .hoverUnderline, .hoverUnderline:hover {
    text-decoration: underline;
    cursor: pointer;
}

.purpleText {
    color: #6b3374;
}