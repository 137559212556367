.stripedTable tbody>tr:nth-child(odd) {
    background: #f7f7f7
}

.stripedTable tbody>tr:nth-child(even) {
    background: white;
}

.stripedTable tbody>tr:hover {
    background: #ececec;
}